// import test1 from "../images/test1.jpeg";
// import test2 from "../images/test2.jpeg";
import projImg from "../images/Project-images/projectImg.png";

const projects = [
  {
    id: 1,
    title: "PeriquitosMex",
    summary: "design & development",
    image: projImg,
    siteName:
      "https://github.com/periquitosMex/periquitosMex/tree/main",
    alt: "PeriquitosMex",
  },
  // {
  //   id: 2,
  //   title: "test",
  //   summary: "test",
  //   image: test1,
  //   alt: "This is a test",
  // },
  // {
  //   id: 1,
  //   title: "PeriquitosMex",
  //   summary: "design & development",
  //   image: projImg,
  //   siteName:
  //     "https://github.com/periquitosMex/periquitosMex/tree/main",
  //   alt: "PeriquitosMex",
  // },
  // {
  //   id: 2,
  //   title: "test",
  //   summary: "test",
  //   image: test1,
  //   alt: "This is a test",
  // },
  // {
  //   id: 1,
  //   title: "PeriquitosMex",
  //   summary: "design & development",
  //   image: projImg,
  //   siteName:
  //     "https://github.com/periquitosMex/periquitosMex/tree/main",
  //   alt: "PeriquitosMex",
  // },
  // {
  //   id: 2,
  //   title: "test",
  //   summary: "test",
  //   image: test1,
  //   alt: "This is a test",
  // },
];

export default projects;
